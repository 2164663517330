<template>
    <div>
        <ts-page-title
            :title="$t('customer.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('customer.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button
                    type="info"
                    ghost
                    @click="$router.push({ name: 'customer-create' })"
                    >{{ $t("addNew") }}
                </Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('customer.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                ref="selection"
                @on-select="onSelectCheck"
                @on-select-cancel="onSelectUncheck"
                @on-select-all="onSelectAllCheck"
                @on-select-all-cancel="onSelectAllUncheck"
                stripe
            >
                <template slot-scope="{ row }" slot="photo">
                    <img
                        class="tw-rounded tw-h-8"
                        v-lazy="{
                            src: photo(row),
                            error:
                                row.gender == 'Male'
                                    ? require('@/assets/staff-default-man.png')
                                    : require('@/assets/staff-default-women.png'),
                            loading: require('@/assets/Rolling-1s-200px.svg'),
                            preLoad: 1.3,
                            attempt: 1
                        }"
                    />
                </template>
                <template slot-scope="{ row }" slot="branch">
                    {{ row.branch ? row.branch.branch_name_en : "" }}
                </template>
                <template slot-scope="{ row }" slot="address">
                    <span v-if="$root.$i18n.locale === 'en'"
                        >{{
                            row.village
                                ? row.village.name_en
                                    ? row.village.name_en + ", "
                                    : ""
                                : ""
                        }}
                        {{
                            row.commune
                                ? row.commune.name_en
                                    ? row.commune.name_en + ", "
                                    : ""
                                : ""
                        }}
                        {{
                            row.district
                                ? row.district.name_en
                                    ? row.district.name_en + ", "
                                    : ""
                                : ""
                        }}
                        {{
                            row.province
                                ? row.province.name_en
                                    ? row.province.name_en + "."
                                    : "-"
                                : "-"
                        }}
                    </span>
                    <span v-else
                        >{{
                            row.village
                                ? row.village.name_kh
                                    ? row.village.name_kh + ", "
                                    : ""
                                : ""
                        }}
                        {{
                            row.commune
                                ? row.commune.name_kh
                                    ? row.commune.name_kh + ", "
                                    : ""
                                : ""
                        }}
                        {{
                            row.district
                                ? row.district.name_kh
                                    ? row.district.name_kh + ", "
                                    : ""
                                : ""
                        }}
                        {{
                            row.province
                                ? row.province.name_kh
                                    ? row.province.name_kh + "."
                                    : "-"
                                : "-"
                        }}
                    </span>
                </template>
                <template slot-scope="{ row }" slot="document_type">
                    {{
                        row.document_type ? row.document_type.doc_type_en : ""
                    }} </template
                >
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <Poptip
                            confirm
                            :title="$t('areYouSure')"
                            @on-ok="onDelete(row)"
                            :transfer="true"
                            :ok-text="$t('yes')"
                            :cancel-text="$t('no')"
                        >
                            <a
                                class="ml-2 text-danger"
                                v-tooltip="$t('delete')"
                                :disabled="row._deleting"
                            >
                                <i
                                    class="fas fa-circle-notch fa-spin"
                                    v-if="row._deleting"
                                ></i>
                                <Icon type="ios-trash" size="20" v-else />
                            </a>
                        </Poptip>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-between tw-p-4">
                <div>
                    <!-- <ts-button
                        color="danger"
                        outline
                        class="tw-mr-2 btn-sm"
                        @click="() => [(pdf_view = true), (type = 2)]"
                        :disabled="customer_id_lists.length <= 0"
                    >
                        <i class="far fa-file-pdf"></i>
                        {{ $t('previewPdf') }} ({{ customer_id_lists.length }})
                        items</ts-button
                    > -->
                    <ts-button
                        color="success"
                        outline
                        class="tw-mr-2 btn-sm"
                        @click="onExportExcelList"
                        :disabled="resources.length <= 0 || exporting"
                    >
                        <i
                            class="fas fa-circle-notch fa-spin"
                            v-if="exporting"
                        ></i>
                        <i class="far fa-file-excel" v-else></i>
                        {{ $t('exportExcel') }} ({{
                            customer_id_lists.length == 0
                                ? $t('all')
                                : customer_id_lists.length
                        }}) items</ts-button
                    >
                </div>
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("branchName")
                }}</label>
                <ts-branch-filter @filter="value => (branch_id = value)" />
            </div>
        </Drawer>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce, trim } from 'lodash'
import * as FileDownload from 'downloadjs'
export default {
    name: "customerIndex",
    data() {
        return {
            loading: false,
            width: "width: 300px",
            visible: false,
            branch_id: [],
            customer_id_lists: [],
            exporting: false
        };
    },
    computed: {
        ...mapState("creditOperation/customer", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.creditOperation.customer.search;
            },
            set(newValue) {
                this.$store.commit(
                    "creditOperation/customer/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "creditOperation/customer/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    type: "selection",
                    width: 60,
                    align: "center"
                },
                {
                    title: this.$t("customer.branch"),
                    slot: "branch",
                    minWidth: 150
                },
                {
                    title: this.$t("customer.photo"),
                    slot: "photo",
                    minWidth: 80
                },
                {
                    title: this.$t("customer.customerCode"),
                    key: "customer_code",
                    minWidth: 130,
                    align: "center",
                    sortable: true
                },
                {
                    title: this.$t("customer.nameKh"),
                    key: "customer_name_kh",
                    minWidth: 170,
                    sortable: true
                },
                {
                    title: this.$t("customer.nameEn"),
                    key: "customer_name_en",
                    minWidth: 250,
                    sortable: true
                },
                {
                    title: this.$t("customer.sex"),
                    key: "gender",
                    minWidth: 90,
                    align: "center"
                },
                {
                    title: this.$t("customer.marritalStatus"),
                    key: "marrital_status",
                    minWidth: 200,
                    sortable: true,
                    align: "center"
                },
                {
                    title: this.$t("customer.dateOfBirth"),
                    key: "date_of_birth",
                    minWidth: 150,
                    align: "center",
                    sortable: true
                },
                {
                    title: this.$t("customer.contactNo"),
                    key: "contact_no",
                    minWidth: 150,
                    align: "center",
                    sortable: true
                },
                {
                    title: this.$t("customer.address"),
                    slot: "address",
                    minWidth: 400
                },
                {
                    title: this.$t("customer.documentType"),
                    slot: "document_type",
                    minWidth: 200,
                    sortable: true
                },
                {
                    title: this.$t("customer.ssnNo"),
                    key: "ssn_no",
                    minWidth: 200,
                    align: "center",
                    sortable: true
                },
                {
                    title: this.$t("customer.issuedDate"),
                    key: "issued_date",
                    minWidth: 200,
                    align: "center",
                    sortable: true
                },
                {
                    title: this.$t("customer.expiryDate"),
                    key: "expiry_date",
                    minWidth: 200,
                    align: "center",
                    sortable: true
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    width: 90
                }
            ];
        }
    },
    methods: {
        photo(record) {
            if (record.photo) {
                return record.photo;
            }

            return record.gender == "Male"
                ? require("@/assets/staff-default-man.png")
                : require("@/assets/staff-default-women.png");
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("creditOperation/customer/fetch", attributes)
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onEdit(record) {
            this.$store.commit(
                "creditOperation/customer/SET_EDIT_DATA",
                record
            );
            this.$router.push({
                name: "customer-edit",
                params: { id: record.customer_id }
            });
        },
        onSelectCheck (record) {
            this.setCustomerListSelected(record)
        },
        onSelectUncheck (record) {
            this.setCustomerListSelected(record)
        },
        onSelectAllCheck (record) {
            this.setCustomerListSelected(record)
        },
        onSelectAllUncheck (record) {
            this.setCustomerListSelected(record)
        },
        handleSelectAll (status) {
            this.$refs.selection.selectAll(status)
        },
        setCustomerListSelected (records) {
            this.customer_id_lists = []
            if (records.length > 0) {
                records.map(row => {
                    this.customer_id_lists.push(row.customer_id)
                })
            }
        },
        async onDelete(record) {
            record._deleting = true;
            this.$store
                .dispatch(
                    "creditOperation/customer/destroy",
                    record.customer_id
                )
                .then(response => {
                    this.fetchData();
                    record._deleting = false;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._deleting = false;
                });
        },
        onExportExcelList () {
            this.exporting = true
            this.$store
                .dispatch('creditOperation/customer/exportExcel', {
                    customer_id_lists: this.customer_id_lists
                })
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    )
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.exporting = false
                    this.customer_id_lists = []
                    this.handleSelectAll(false)
                })
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "CUSTOMER PROFILE",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search, branch_id: this.branch_id });
        }, 500),
        branch_id: debounce(function(value) {
            this.fetchData({ branch_id: value });
        }, 500)
    },
    // beforeRouteEnter(to, from, next) {
    //     next(vm => {
    //         vm.fetchData();
    //     });
    // },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("creditOperation/customer/RESET_STATE");
        next();
    }
};
</script>
<style scoped>
.text-nowrap {
    white-space: nowrap;
}
</style>
